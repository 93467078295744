import { createTheme } from '@material-ui/core/styles';

export const DRAWER_WIDTH = 290;

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '0.5rem',
        height: '3rem',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '400',
        border: '1px solid #D0D0DE',
      },
    },
    MuiInputLabel: {
      outlined: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '400',
        lineHeight: '1rem',
        transform: 'translate(1rem, 1rem)',
      },
    },
    MuiInputAdornment: {
      root: {
        color: '#939CAE',
      },
    },
    MuiButton: {
      root: {
        marginTop: '1rem',
        borderRadius: '5px',
        height: '3.1rem',
      },
      label: {
        textTransform: 'none',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '500',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      },
    },
    MuiContainer: {
      maxWidthMd: {
        maxWidth: 1024,
        '@media (min-width: 960px)': {
          maxWidth: 1024,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'fit-content',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#939CAE',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#292D30',
      },
    },
  },
  palette: {
    text: {
      primary: '#1B2559',
      secondary: '#FFF',
      grey: '#A3AED0',
      hover: '#E3E7F1',
    },
    primary: {
      main: '#FF5315',
      gradient: 'linear-gradient(90deg, #FF5300 0%, #FE3E4B 100%)',
      hover: '#E64B13',
    },
    secondary: {
      main: '#292D30',
      dark: '#1A202C',
    },
    background: {
      default: '#F2F4F9',
      white: '#FFF',
      primary: '#FF5315',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'DM sans, sans-serif',
      letterSpacing: '-0.02em',
    },
    h4: {
      marginTop: '0.5rem',
      marginBottom: '1rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1rem',
      letterSpacing: 'normal',
      fontFamily: 'Roboto',
    },
    subtitle2: {
      fontSize: '0.75rem',
      color: '#707EAE',
    },
  },
});

export default theme;
