import { firebaseApp, firestore, auth } from './firebaseApp';
import { getLessonsInTrackId } from './fsContentStore';

const getUserToken = async (): Promise<string> => {
  const { currentUser } = auth;
  try {
    const token = await currentUser.getIdToken(true);
    return token;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// function that retrieves courses from firestore
const getAllCourses = async () => {
  return await firebaseApp
    .auth()
    .currentUser.getIdTokenResult()
    .then(async (idTokenResult) => {
      if (idTokenResult.claims.admin) {
        const courses = [];
        return await firestore
          .collection('courses')
          .get()
          .then((querySnap) => {
            querySnap.forEach((doc) => {
              courses.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            return {
              message: 'Successfully retrieved courses',
              courses,
            };
          });
      } else {
        return {
          message: 'Must be an admin to make this request',
          courses: [],
        };
      }
    })
    .catch((error) => {
      return {
        message: 'There has been an error retrieving courses',
        courses: [],
        error: error.message,
      };
    });
};

// returns data associated with a track for the given trackId
const getTrackData = async (trackId) => {
  return await firebaseApp
    .auth()
    .currentUser.getIdTokenResult()
    .then(async (idTokenResult) => {
      if (idTokenResult.claims.admin) {
        return await firestore
          .collection('tracks')
          .doc(trackId)
          .get()
          .then(async (snapshot) => {
            const snapshotData = snapshot.data();
            const lessonObjects = await getLessonsInTrackId(trackId);
            return {
              message: 'Successfully retrieved course',
              track: {
                id: trackId,
                name: snapshotData.name,
                lessonIds: snapshotData.lessonIds,
                lessonObjects: lessonObjects,
              },
            };
          });
      } else {
        return {
          message: 'You must be an admin to make this request',
          course: null,
        };
      }
    })
    .catch((error) => {
      console.error(error);
      return {
        message: 'There has been an error retrieving the course',
        error: error.message,
        course: null,
      };
    });
};

// returns an array of track objects containing all tracks in the database
const getAllTracks = async () => {
  return await firebaseApp
    .auth()
    .currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      if (idTokenResult.claims.admin) {
        let tracksArray = [];
        return firestore
          .collection('tracks')
          .get()
          .then(async (snapshot) => {
            snapshot.forEach((track) => {
              tracksArray.push({
                id: track.id,
                name: track.data().name,
                lessonIds: track.data().lessonIds,
              });
            });
            return {
              message: 'Successfully retrieved tracks',
              tracksArray: tracksArray,
            };
          })
          .catch((error) => {
            console.error(error);
            return {
              message: 'There was an error retrieving the tracks',
              error: error.message,
              tracksArray: null,
            };
          });
      } else {
        return {
          message: 'You must be an admin to make this request',
          tracksArray: [],
        };
      }
    })
    .catch((error) => {
      console.error(error.message);
      return {
        message: 'There has been an error getting idTokenResult',
        error: error.message,
        tracksArray: null,
      };
    });
};

// returns data associated with a lesson given the lessonId
const getLessonData = async (lessonId) => {
  return firebaseApp
    .auth()
    .currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      if (idTokenResult.claims.admin) {
        return firestore
          .collection('lessons')
          .doc(lessonId)
          .get()
          .then((snap) => {
            if (snap.data()) {
              return {
                message: 'Successfully retrieved lesson data',
                lesson: {
                  id: snap.id,
                  name: snap.data().name,
                  type: snap.data().type,
                  lessonUrl: snap.data().lessonUrl,
                  contentType: snap.data().contentType,
                  submittable: snap.data().submittable,
                  groupProject: snap.data().groupProject,
                  createdAt: snap.data().createdAt,
                },
              };
            } else {
              return {
                message: `No lesson data with id ${lessonId}`,
                lesson: {
                  id: lessonId,
                  name: '',
                  type: '',
                  lessonUrl: '',
                  contentType: '',
                  submittable: '',
                  groupProject: '',
                  createdAt: '',
                },
              };
            }
          })
          .catch((error) => {
            console.error(error.message);
            return {
              message: 'There has been an error retrieving lesson data',
              lesson: {},
            };
          });
      }
    })
    .catch((error) => {
      return {
        message: 'There has been an error getting idTokenResult',
        error: error.message,
        lesson: {},
      };
    });
};

// returns an array of lesson objects containing all lessons in the database
const getAllLessons = async () => {
  return await firebaseApp
    .auth()
    .currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      if (idTokenResult.claims.admin) {
        let lessonsArray = [];
        return firestore
          .collection('lessons')
          .get()
          .then((snapshot) => {
            snapshot.forEach((lesson) => {
              lessonsArray.push({
                id: lesson.id,
                name: lesson.data().name,
                type: lesson.data().type,
                lessonUrl: lesson.data().lessonUrl,
              });
            });
            return {
              message: 'Successfully retrieved lessons',
              lessonsArray: lessonsArray,
            };
          })
          .catch((error) => {
            console.error(error);
            return {
              message: 'There was an error retrieving the lessons',
              error: error.message,
              lessonsArray: null,
            };
          });
      } else {
        return {
          message: 'You must be an admin to make this request',
          lessonsArray: null,
        };
      }
    })
    .catch((error) => {
      console.error(error.message);
      return {
        message: 'There has been an error getting idTokenResult',
        error: error.message,
        lessonsArray: null,
      };
    });
};

// gets the user claims on a users auth token
// checks if they are a member or admin
// talks to FIREBASE AUTH
const getUserClaims = async () => {
  return auth.currentUser
    .getIdTokenResult()
    .then((idTokenResult) => {
      const { member, admin, instructor, publicUser } = idTokenResult.claims;
      return { member, admin, instructor, publicUser };
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export {
  getUserToken,
  getTrackData,
  getLessonData,
  getAllCourses,
  getAllTracks,
  getAllLessons,
  getUserClaims,
};
