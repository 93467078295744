// constants file to import into pages and components;
const ACTION_TYPES = {
  SET: 'SET_FIELD',
  SET_ERROR: 'SET_ERROR',
  RESET: 'RESET',
  SIGNIN: 'SIGN_IN',
  SHOW_MODAL: 'SHOW_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
};

const APP_TYPES = {
  TRACK: 'track',
  MODULE: 'module',
};

const COLLECTIONS = {
  ACTIVITIES: 'activities',
  COURSES: 'courses',
  LESSONS: 'lessons',
  MODULES: 'modules',
  RESOURCES: 'resources',
  TEMPLATES: 'templates',
  TRACKS: 'tracks',
  USERS: 'users',
};

const CONSTANTS = {
  MIN_GRADE: 0,
  MAX_GRADE: 4,
  PAGE_REFRESH_TIME_MS: 3000, // how long until a user is redirected automatically
};

/** IDs correspond to documents in firestore
 *  course id is mapped to necessary initial
 *  values to populate a new user document with
 *
  [courseId]: {
    COURSE_ID: [courseId],
    ...
  }
*/

const COURSE_DEFAULTS = {
  // This is the original Web Dev Prework course
  // TODO: this key should be updated to look like intro to js
  V23waRCyBAgySoK4HYAz: {
    COURSE_ID: 'V23waRCyBAgySoK4HYAz',
    TRACK_ID: 'Oo4USIpjQlmDgWticKS0',
    LESSON_ID: 'TMbnGDo4P89L0TI4kOLD',
    MODULE_ID: 'Oo4USIpjQlmDgWticKS0',
    ACTIVITY_ID: 'TMbnGDo4P89L0TI4kOLD',
  },
  // this is the new async intro to js course
  INTRO_TO_WEB_DEV: {
    NAME: 'Intro to Web Development',
    COURSE_ID: 'jwIiaTJJP0VsiK6KKZHF',
    TRACK_ID: 'Oo4USIpjQlmDgWticKS0',
    LESSON_ID: 'TMbnGDo4P89L0TI4kOLD',
    MODULE_ID: 'Oo4USIpjQlmDgWticKS0',
    ACTIVITY_ID: 'TMbnGDo4P89L0TI4kOLD',
  },
};

const UNAUTHED_ROUTES = new Set([
  '/',
  '/email-action',
  '/signin',
  '/signup',
  '/verify',
  '/terms-and-conditions',
  '/privacy-policy',
]);

const SLIDES_TYPES = {
  GOOGLE: 'googleSlides',
  LEGACY: 'legacySlides',
  MARKDOWN: 'markdownSlides',
};

const SUBCOLLECTIONS = {
  ASSIGNMENTS: 'assignments',
  MODULES: 'modules',
  SUBMISSIONGROUP: 'submissionGroup',
  SUBMISSIONS: 'submissions',
  TRACKS: 'tracks',
};

export {
  ACTION_TYPES,
  APP_TYPES,
  CONSTANTS,
  COLLECTIONS,
  COURSE_DEFAULTS,
  UNAUTHED_ROUTES,
  SLIDES_TYPES,
  SUBCOLLECTIONS,
};
