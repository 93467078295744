import { auth, firestore } from '../firebaseApp';
import type { CurrentCourse, CurrentModule, CurrentActivity } from '../types';

const setCourseProgressForModules = async (
  courseId: string,
  numActivitiesCompleted: number,
  percentCompleted: number
) => {
  const uid = auth.currentUser.uid;
  return await firestore
    .collection('users')
    .doc(uid)
    .collection('courseStatus')
    .doc(courseId)
    .set(
      {
        numActivitiesCompleted,
        percentCompleted,
      },
      { merge: true }
    )
    .catch((err) => {
      throw new Error(err.message);
    });
};

// sets the status of the courseStatus sub collection under a user
// this is used to retrieve the current lesson and track as
// well as the next lesson for a specific course
const setCourseStatusForModules = async (
  courseObj: CurrentCourse,
  moduleObj: CurrentModule,
  activityObj: CurrentActivity
): Promise<any> => {
  const uid = auth.currentUser.uid;
  return firestore
    .collection('users')
    .doc(uid)
    .collection('courseStatus')
    .doc(courseObj.id)
    .set(
      {
        moduleId: moduleObj.id,
        activityId: activityObj.id,
      },
      { merge: true }
    )
    .catch((err) => {
      throw new Error(err.message);
    });
};

// sets/updates the info about the current course, module, activity
// in firestore, as well as setting the completion info
const setCurrentActivityData = (
  uid: string,
  {
    currentCourse,
    currentModule,
    currentActivity,
    activitiesCompleted,
    modulesCompleted,
  }
) => {
  console.log('setCurrentActivityData');
  const courseId = currentCourse.id;
  const moduleId = currentModule.id;
  const activityId = currentActivity.id;
  console.log({
    uid,
    currentCourse,
    currentModule,
    currentActivity,
    activitiesCompleted,
    modulesCompleted,
  });
  return firestore.collection('users').doc(uid).set(
    {
      courseId,
      moduleId,
      activityId,
      activitiesCompleted: activitiesCompleted,
      modulesCompleted: modulesCompleted,
    },
    { merge: true }
  );
};

export {
  setCourseProgressForModules,
  setCourseStatusForModules,
  setCurrentActivityData,
};
