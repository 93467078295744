import { firestore } from './firebaseApp';
import { CurrentCourse } from './types';

/**
 * Updates the course id of the currentCourse property on the user's
 * firestore document
 *
 * @param course a Course object type
 * @param uid the user's uid / doc id
 * @returns void Promise
 */
export const updateCourse = async (
  course: CurrentCourse,
  uid: string
): Promise<void> => {
  return firestore
    .collection('users')
    .doc(uid)
    .update({
      courseId: course.id,
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

export const updateCurrentLesson = async (
  uid: string,
  { currentCourse, currentTrack, currentLesson }
) => {
  const courseId = currentCourse.id;
  const trackId = currentTrack.id;
  const lessonId = currentLesson.id;
  return firestore
    .collection('users')
    .doc(uid)
    .set(
      {
        courseId,
        trackId,
        lessonId,
      },
      { merge: true }
    )
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};
