import { useContext, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRouter } from 'next/router';

import { auth } from '@/src/firebaseApp';
import FirestoreUserContext from '@/src/FirestoreUserContext';
import Link from '@/src/Link';
import UserContext from '@/src/UserContext';

// material-ui jss using makeStyles
const useStyles = makeStyles((theme) => ({
  invalidUser: {
    position: 'absolute',
    width: '60vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '& > *': {
      fontSize: '1.25rem',
    },
    '& a': {
      textDecoration: 'underline',
    },
  },
}));

const UnauthedUserPage = () => {
  const classes = useStyles();

  const { user, loading } = useContext(FirestoreUserContext);
  const { loadingData } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    if (router.pathname === '/lessons/view/[id]' && !user && !loading) {
      auth.signInAnonymously();
    }
  }, [router.pathname, user, loading]);

  const renderUnauthedView = () => {
    return (
      <>
        <Typography gutterBottom>
          You must be logged in with a valid email / user in order to see
          content. Click <Link href='/'>here</Link> to log in.
        </Typography>
        <Typography gutterBottom>
          If you are a student and don&apos;t have access, please contact BCA
          staff.
        </Typography>
        <Typography gutterBottom>
          You can get more info about Burlington Code Academy by following{' '}
          <a
            href='https://www.burlingtoncodeacademy.com/about/'
            rel='noopener noreferrer'
            target='_blank'
          >
            this link
          </a>
          .
        </Typography>
        <Typography gutterBottom>
          You can visit{' '}
          <a
            href='https://www.burlingtoncodeacademy.com/apply/'
            rel='noopener noreferrer'
            target='_blank'
          >
            this link
          </a>{' '}
          to apply to our program.
        </Typography>
      </>
    );
  };

  const renderLoadingView = () => {
    return <Typography>Loading user...</Typography>;
  };

  return (
    <div className={classes.invalidUser}>
      {(loading || loadingData) && renderLoadingView()}
      {!user && !loading && !loadingData && renderUnauthedView()}
    </div>
  );
};

export default UnauthedUserPage;
