import { createContext } from 'react';

import { appState } from './init/app';
import type {
  CurrentActivity,
  CurrentCourse,
  CurrentLesson,
  CurrentModule,
  CurrentTrack,
} from './types';

const UserContext = createContext({
  isCompleted: (act, module): boolean => false,
  setCourse: (course: CurrentCourse) => {},
  setAsyncCourse: async (course: CurrentCourse): Promise<void> => {},
  trackActivityStatus: (
    act: CurrentActivity,
    module: CurrentModule
  ): void => {},
  trackLessonStatus: (lesson: CurrentLesson, track: CurrentTrack) => {},
  trackAsyncLessonStatus: async (
    lesson: CurrentLesson,
    track: CurrentTrack
  ): Promise<void> => {},
  ...appState,
});

UserContext.displayName = 'UserContext';

export default UserContext;
