import { COLLECTIONS } from '../../constants';
import { firestore } from '../../firebaseApp';
import { getLessonAndTrackLists } from '../../fsContentStore';
import { getModuleAndActivityLists } from '../get';
import type {
  ActivityDocument,
  ExtendedCourseDocument,
  ModuleDocument,
  ResourceDocument,
} from '../../types';

/**
 * Looks up the activity details from root activity collection
 * and returns the document data
 *
 * @param activityId The ID of the requested activity
 * @returns ActivityDocument object
 */
const getActivityDoc = async (
  activityId: string
): Promise<ActivityDocument> => {
  const activityDoc: ActivityDocument = {
    id: activityId,
    createdAt: null,
    name: '',
    resources: [],
    group: false,
    submittable: false,
    type: '',
  };
  if (!activityId) return activityDoc;
  return firestore
    .collection(COLLECTIONS.ACTIVITIES)
    .doc(activityId)
    .get()
    .then((snap) => {
      if (!snap.exists) return activityDoc;
      activityDoc.createdAt = snap.data().createdAt;
      activityDoc.name = snap.data().name;
      activityDoc.resources = snap.data().resources;
      activityDoc.group = snap.data().group;
      activityDoc.submittable = snap.data().submittable;
      activityDoc.type = snap.data().type;
      return activityDoc;
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

// combination function which gets data and combines it onto one object
// returns all data on a course document with the given document id
// plus more infomation about tracks, lessons, modules, and activities
const getCourseDoc = async (
  courseId: string
): Promise<ExtendedCourseDocument> => {
  return firestore
    .collection(COLLECTIONS.COURSES)
    .doc(courseId)
    .get()
    .then(async (snap) => {
      const { trackList, lessonList } = await getLessonAndTrackLists(courseId);
      const { moduleList, activityList } = await getModuleAndActivityLists(
        courseId
      );
      const course = {
        id: courseId,
        trackList,
        moduleList,
        activityList,
        lessonList,
        name: snap.data().name,
        type: snap.data().type,
        trackIds: snap.data().trackIds,
        moduleIds: snap.data().moduleIds,
        start: snap.data().start,
        startDate: snap.data().startDate,
        end: snap.data().end,
        endDate: snap.data().endDate,
        students: snap.data().students,
        studentsArr: snap.data().studentsArr,
      };
      return course;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

/**
 * Looks up the module details from root module collection
 * and returns the document data
 *
 * @param moduleId The ID of the requested module
 * @returns ModuleDocument object
 */
const getModuleDoc = async (moduleId: string): Promise<ModuleDocument> => {
  const moduleDoc: ModuleDocument = {
    createdAt: null,
    id: moduleId,
    name: '',
    activities: [],
  };
  if (!moduleId) return moduleDoc;
  return firestore
    .collection(COLLECTIONS.MODULES)
    .doc(moduleId)
    .get()
    .then((snap) => {
      if (!snap.exists) return moduleDoc;
      moduleDoc.createdAt = snap.data().createdAt;
      moduleDoc.name = snap.data().name;
      moduleDoc.activities = snap.data().activities;
      return moduleDoc;
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

/**
 * Looks up the resource details from root resource collection
 * and returns the document data
 *
 * @param resourceId The ID of the requested resource
 * @returns ResourceDocument object
 */
const getResourceDoc = async (
  resourceId: string
): Promise<ResourceDocument> => {
  const resourceDoc: ResourceDocument = {
    createdAt: null,
    id: resourceId,
    location: '',
    name: '',
    type: '',
  };
  if (!resourceId) return resourceDoc;
  return firestore
    .collection(COLLECTIONS.RESOURCES)
    .doc(resourceId)
    .get()
    .then((snap) => {
      if (!snap.exists) return resourceDoc;
      resourceDoc.createdAt = snap.data().createdAt;
      resourceDoc.location = snap.data().location;
      resourceDoc.name = snap.data().name;
      resourceDoc.type = snap.data().type;
      return resourceDoc;
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

export { getActivityDoc, getCourseDoc, getModuleDoc, getResourceDoc };
