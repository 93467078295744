import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import firebase from 'firebase/app';
import App from 'next/app';
import Head from 'next/head';
import Router, { withRouter } from 'next/router';
import { useAuthState } from 'react-firebase-hooks/auth';

import { updateCourse, updateCurrentLesson } from '@/src/asyncUtils';
import { UNAUTHED_ROUTES } from '@/src/constants';

import { getUserClaims } from '../src/authContentStore';
import CourseContext from '../src/CourseContext';
import { firebaseApp, firestore, auth } from '../src/firebaseApp';
import {
  getCompletionInfo,
  getCourseStatusForModules,
  getCurrentActivity,
  getCurrentModule,
  getModuleAndActivityLists,
  getUserCourses,
} from '../src/firestore/get';
import {
  setCurrentActivityData,
  setCourseStatusForModules,
} from '../src/firestore/set';
import FirestoreUserContext from '../src/FirestoreUserContext';
import {
  getCurrentCourse,
  getCurrentLesson,
  getCourseStatusForTracks,
  setCourseStatusForTracks,
  setCurrentLessonData,
  getCurrentTrack,
  getLessonAndTrackLists,
  getOnboardingStatus,
} from '../src/fsContentStore';
import { appState } from '../src/init/app';
import type {
  CurrentActivity,
  CurrentModule,
  CurrentLesson,
  CurrentTrack,
  CurrentCourse,
} from '../src/types';
import theme from '../src/uprightTheme';
import UserContext from '../src/UserContext';
import UnauthedUserPage from './UnauthedUser';

// Base app containing all of the apps state variables that can be accessed through a UserContext
class DynamicApp extends App {
  state = {
    loadingData: true,
    isCompleted: (act, module): boolean => false,
    setAsyncCourse: async (course: CurrentCourse): Promise<void> => {},
    trackActivityStatus: (
      act: CurrentActivity,
      module: CurrentModule
    ): void => {},
    trackLessonStatus: (lesson: CurrentLesson, track: CurrentTrack) => {},
    trackAsyncLessonStatus: async (
      lesson: CurrentLesson,
      track: CurrentTrack
    ): Promise<void> => {},
    ...appState,
  };

  moduleFunctions = {
    completeActivity: (activity, module) => {
      // get course and activity completion info
      const { currentCourse, activitiesCompleted } = this.state;
      const courseId = currentCourse.id;
      const moduleId = module.id;
      const activityId = activity.id;
      const timestamp = firebase.firestore.Timestamp.now();

      const activitiesMap = new Map(Object.entries(activitiesCompleted));

      activitiesMap.set(courseId, {
        ...activitiesCompleted[courseId],
        [moduleId]: {
          ...activitiesCompleted[courseId][moduleId],
          [activityId]: timestamp,
        },
      });

      const newActivitiesCompleted = Object.fromEntries(activitiesMap);
      return newActivitiesCompleted;
    },
    completeModule: (module) => {
      // get course and module info from state
      const { currentCourse, modulesCompleted } = this.state;
      const courseId = currentCourse.id;
      const moduleId = module.id;

      // create map from modulesCompleted object
      const modulesMap = new Map(Object.entries(modulesCompleted));
      const courseObj = modulesMap.get(courseId);

      const timestamp = firebase.firestore.Timestamp.now();
      const courses = Object.assign({}, courseObj);

      modulesMap.set(courseId, {
        ...courses,
        [moduleId]: timestamp,
      });

      const newModulesCompleted = Object.fromEntries(modulesMap);

      return newModulesCompleted;
    },
    // checks state.activitiesCompleted,
    // checks is a activity is complete, returns true/false
    // talks to STATE
    isCompleted: (activity, module, newActivitiesCompleted = null) => {
      if (!activity || !module) {
        return false;
      }
      let completed = false;
      const { currentCourse, activitiesCompleted } = this.state;

      // if there is a activitiesCompleted object passed in, use that, otherwise use the state
      // this is in case we are checking if a module is complete
      const completionObj = newActivitiesCompleted || activitiesCompleted;

      // if there are activities completed in the course, and activities completed in the current module
      if (
        completionObj[currentCourse.id] &&
        completionObj[currentCourse.id][module.id]
      ) {
        const activitiesInModule = completionObj[currentCourse.id][module.id];
        const activities = Object.keys(activitiesInModule);
        if (activities.includes(activity.id)) completed = true;
      }

      // return completed
      return completed;
    },
    // set the current active course, gets course info from firestore
    // sets the state, the sets user data (rtdb)
    // talks to STATE, FIRESTORE, && RTDB
    setCourse: async (course) => {
      const uid = auth.currentUser.uid;
      const { currentActivity, currentModule } =
        await getCourseStatusForModules(course.id);
      this.setState(
        {
          currentCourse: course,
          currentActivity: currentActivity,
          currentModule: currentModule,
        },
        () => {
          setCurrentActivityData(uid, this.state);
          const pathPartsArr = Router.pathname.split('/');
          if (
            !pathPartsArr.includes('admin') &&
            !pathPartsArr.includes('instructor')
          ) {
            Router.push('/dashboard');
          }
        }
      );
    },
    // Track current activity/module in the state and sets user data in db
    // talks to STATE and FIRESTORE
    // takes full objects
    trackActivityStatus: (activity, module) => {
      const uid = auth.currentUser.uid;
      this.setState(
        {
          currentModule: module,
          currentActivity: activity,
        },
        async () => {
          const { currentCourse, currentModule, currentActivity } = this.state;
          await setCurrentActivityData(uid, this.state);
          await setCourseStatusForModules(
            currentCourse,
            currentModule,
            currentActivity
          );
        }
      );
    },
  };

  trackFunctions = {
    completeLesson: (lesson, track) => {
      // get course and lesson completion info
      const { currentCourse, lessonsCompleted } = this.state;
      const courseId = currentCourse.id;
      const trackId = track.id;
      const lessonId = lesson.id;
      const timestamp = firebase.firestore.Timestamp.now();

      const lessonsMap = new Map(Object.entries(lessonsCompleted));

      lessonsMap.set(courseId, {
        ...lessonsCompleted[courseId],
        [trackId]: {
          ...lessonsCompleted[courseId][trackId],
          [lessonId]: timestamp,
        },
      });

      const newLessonsCompleted = Object.fromEntries(lessonsMap);
      return newLessonsCompleted;
    },
    completeTrack: (track) => {
      // get course and track info from state
      const { currentCourse, tracksCompleted } = this.state;
      const courseId = currentCourse.id;
      const trackId = track.id;

      // create map from tracksCompleted object
      const tracksMap = new Map(Object.entries(tracksCompleted));
      const courseObj = tracksMap.get(courseId);

      const timestamp = firebase.firestore.Timestamp.now();
      const courses = Object.assign({}, courseObj);

      tracksMap.set(courseId, {
        ...courses,
        [trackId]: timestamp,
      });

      const newTracksCompleted = Object.fromEntries(tracksMap);

      return newTracksCompleted;
    },
    // checks state.lessonsCompleted,
    // checks is a lesson is complete, returns true/false
    // talks to STATE
    isCompleted: (lesson, track, newLessonsCompleted = null) => {
      if (!lesson || !track) {
        return false;
      }
      let completed = false;
      const { currentCourse, lessonsCompleted } = this.state;

      // if there is a lessonsCompleted object passed in, use that, otherwise use the state
      // this is in case we are checking if a track is complete
      const completionObj = newLessonsCompleted || lessonsCompleted;

      // if there are lessons completed in the course, and lessons completed in the current track
      if (
        completionObj[currentCourse.id] &&
        completionObj[currentCourse.id][track.id]
      ) {
        const lessonsInTrack = completionObj[currentCourse.id][track.id];
        const lessons = Object.keys(lessonsInTrack);
        if (lessons.includes(lesson.id)) completed = true;
      }

      // return completed
      return completed;
    },
    // set the current active course, gets course info from firestore
    // sets the state, the sets user data (rtdb)
    // talks to STATE, FIRESTORE, && RTDB
    setCourse: async (course) => {
      const uid = auth.currentUser.uid;
      const { currentLesson, currentTrack } = await getCourseStatusForTracks(
        course
      );

      this.setState(
        {
          currentCourse: course,
          currentLesson,
          currentTrack,
        },
        () => {
          setCurrentLessonData(uid, this.state);
          const pathPartsArr = Router.pathname.split('/');
          if (
            !pathPartsArr.includes('admin') &&
            !pathPartsArr.includes('instructor')
          ) {
            Router.push('/dashboard');
          }
        }
      );
    },
    // Track current lesson/track in the state and sets user data in db
    // talks to STATE and FIRESTORE
    // takes full objects
    trackLessonStatus: (lesson, track) => {
      const uid = auth.currentUser.uid;
      this.setState(
        {
          currentTrack: track,
          currentLesson: lesson,
        },
        () => {
          const { currentCourse, currentTrack, currentLesson } = this.state;
          setCurrentLessonData(uid, this.state);
          setCourseStatusForTracks(currentCourse, currentTrack, currentLesson);
        }
      );
    },
    setAsyncCourse: async (course: CurrentCourse): Promise<void> => {
      const uid = auth.currentUser.uid;
      this.setState(
        {
          currentCourse: course,
        },
        async () => {
          await updateCourse(course, uid);
        }
      );
    },
    trackAsyncLessonStatus: async (lesson, track): Promise<void> => {
      const uid = auth.currentUser.uid;
      this.setState(
        {
          currentTrack: track,
          currentLesson: lesson,
        },
        async () => {
          const { currentCourse, currentTrack, currentLesson } = this.state;
          await updateCurrentLesson(uid, this.state);
          await setCourseStatusForTracks(
            currentCourse,
            currentTrack,
            currentLesson
          );
        }
      );
    },
  };

  async mountModuleApp(user) {
    const { member, admin, instructor, publicUser } = await getUserClaims();
    const onboardingComplete = await getOnboardingStatus(user.uid);

    // we can probably cut down the number of requests here if we store duplicate
    // data on the user doc, i.e. id and name combos
    const { activitiesCompleted, modulesCompleted } = await getCompletionInfo(
      user.uid
    );
    const courseList = await getUserCourses(user.uid);
    const currentCourse = await getCurrentCourse(user.uid);
    const currentModule = await getCurrentModule(user.uid);
    const currentActivity = await getCurrentActivity(user.uid);

    // get modules for the current course
    const { moduleList, activityList } = await getModuleAndActivityLists(
      currentCourse.id
    );

    const numActivities = activityList.length;

    this.setState({
      member,
      admin,
      instructor,
      publicUser,
      onboardingComplete,
      numActivities,
      currentCourse,
      currentModule,
      currentActivity,
      activityList,
      moduleList,
      courseList,
      activitiesCompleted,
      modulesCompleted,
      loadingData: false,
      ...this.moduleFunctions,
    });
  }

  async mountTrackApp(user) {
    firestore
      .collection('users')
      .doc(user.uid)
      .onSnapshot((snap) => {
        if (!snap.exists) return;
        this.setState({
          lessonsCompleted: snap.data().lessonsCompleted,
          tracksCompleted: snap.data().tracksCompleted,
        });
      });
    const { member, admin, instructor, publicUser } = await getUserClaims();
    const onboardingComplete = await getOnboardingStatus(user.uid);

    // we can probably cut down the number of requests here if we store duplicate
    // data on the user doc, i.e. id and name combos
    const courseList = await getUserCourses(user.uid);
    const currentCourse = await getCurrentCourse(user.uid);
    const currentTrack = await getCurrentTrack(user.uid);
    const currentLesson = await getCurrentLesson(user.uid);

    // get tracks for the current course
    const { trackList, lessonList } = await getLessonAndTrackLists(
      currentCourse.id
    );

    const numLessons = lessonList.length;

    this.setState({
      member,
      admin,
      instructor,
      publicUser,
      onboardingComplete,
      numLessons,
      currentCourse,
      currentTrack,
      currentLesson,
      lessonList,
      trackList,
      courseList,
      loadingData: false,
      ...this.trackFunctions,
    });
  }

  async componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    firebaseApp.auth().onAuthStateChanged(async (user) => {
      const {
        pageProps: { appType, setAppType },
      } = this.props;

      if (user) {
        const uids = await firestore
          .collection('flags')
          .doc('usingModules')
          .get()
          .then((snap) => {
            if (!snap.exists) return [];
            return snap.data().userIds;
          })
          .catch((error) => {
            console.error(error.message);
            return [];
          });

        if (uids.includes(user.uid) && appType !== 'module') {
          setAppType('module');
          this.mountModuleApp(user);
        } else if (appType === 'module') {
          this.mountModuleApp(user);
        } else {
          this.mountTrackApp(user);
        }
      } else {
        const { router } = this.props;
        if (router.pathname !== 'lessons/view/[id]') {
          this.setState({ loadingData: false });
        }
      }
    });
  }

  async updateModuleApp(prevState) {
    // only fetch / update these things if there is a user logged in
    if (!auth.currentUser) return;

    if (this.state.currentCourse && this.state.loadingData) {
      this.setState({ loadingData: false });
    }

    if (
      prevState.currentCourse?.id &&
      this.state.currentCourse?.id !== prevState.currentCourse?.id
    ) {
      const currentCourse = this.state.currentCourse;
      const currentModule = this.state.currentModule;
      const currentActivity = this.state.currentActivity;

      // get modules and activities for the current course
      const { moduleList, activityList } = await getModuleAndActivityLists(
        currentCourse.id
      );

      const numActivities = activityList.length;

      this.setState({
        numActivities,
        moduleList,
        activityList,
        currentModule,
        currentActivity,
        loadingData: false,
      });
    }
  }

  async updateTrackApp(prevState) {
    // only fetch / update these things if there is a user logged in
    if (!auth.currentUser) return;

    if (this.state.currentCourse && this.state.loadingData) {
      this.setState({ loadingData: false });
    }
    if (this.state.currentCourse?.id !== prevState.currentCourse?.id) {
      const currentCourse = this.state.currentCourse;
      // get tracks and lessons for the current course
      const { trackList, lessonList } = await getLessonAndTrackLists(
        currentCourse.id
      );

      const numLessons = lessonList.length;

      this.setState({
        numLessons,
        trackList,
        lessonList,
        loadingData: false,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      pageProps: { appType },
    } = this.props;

    appType === 'module'
      ? this.updateModuleApp(prevState)
      : this.updateTrackApp(prevState);
  }

  render() {
    const { Component, pageProps, router } = this.props;
    const {
      currentCourse,
      currentTrack,
      currentLesson,
      lessonList,
      trackList,
      currentModule,
      currentActivity,
      activityList,
      moduleList,
    } = this.state;
    const courseContext = {
      currentCourse,
      currentTrack,
      currentLesson,
      lessonList,
      trackList,
      currentModule,
      currentActivity,
      activityList,
      moduleList,
    };

    const user = auth.currentUser;
    const page = router.route;
    const showPage = UNAUTHED_ROUTES.has(page);

    return (
      <>
        <Head>
          <meta
            content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
            name='viewport'
          />
          {/* PWA primary color */}
          <meta content={theme.palette.primary.main} name='theme-color' />
          <link href='/favicon.ico' rel='icon' type='image/x-icon' />
          <title>Upright Learning Platform</title>
        </Head>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <FireStoreAuthHOC>
            <UserContext.Provider value={this.state}>
              <CourseContext.Provider value={courseContext}>
                {showPage || (user && !this.state.loadingData) ? (
                  <Component {...pageProps} />
                ) : (
                  <UnauthedUserPage />
                )}
              </CourseContext.Provider>
            </UserContext.Provider>
          </FireStoreAuthHOC>
        </ThemeProvider>
      </>
    );
  }
}

const withFireStoreAuthHOC = (AuthProvider) => {
  return function AuthWrapper(props) {
    const [user, loading, error] = useAuthState(auth);
    return <AuthProvider value={{ user, loading }} {...props} />;
  };
};

const FireStoreAuthHOC = withFireStoreAuthHOC(FirestoreUserContext.Provider);

export default withRouter(DynamicApp);
