import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import clientCredentials from '../credentials/client';

const NODE_ENV = process.env.NODE_ENV || 'development';
const APP_ENV = process.env.APP_ENV || '';

//If no firebase app has been initialized, initialize one and set it
// otherwise set current app
const getApp = (appEnv) => {
  if (appEnv === 'emulator' || NODE_ENV === 'test') {
    clientCredentials.databaseURL = `http://localhost:9000/?ns=${clientCredentials.projectId}`;
  }
  return !firebase.apps.length
    ? firebase.initializeApp(clientCredentials)
    : firebase.app();
};

const firebaseApp = getApp(APP_ENV);
const firestore = firebaseApp.firestore();


const googleProvider = new firebase.auth.GoogleAuthProvider();
const githubProvider = new firebase.auth.GithubAuthProvider();
const auth = firebase.auth();

if (NODE_ENV === 'development' && APP_ENV === 'emulator') {
  firestore.settings({
    host: 'localhost:8080',
    ssl: false,
  });
}

export { firebaseApp, firestore, googleProvider, githubProvider, auth };
