import { moduleState } from './moduleApp';
import { trackState } from './trackApp';

const appState = {
  ...trackState,
  ...moduleState,
  currentCourse: { id: '', name: '' },
  courseList: [],
  // TODO: change the way a user is identified, make a 'userType' string field
  member: null,
  admin: null,
  instructor: null,
  publicUser: null,
  onboardingComplete: true,
};

export { appState };
