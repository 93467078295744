import { createContext, useState } from 'react';
import DynamicApp from '../components/DynamicApp';

export const AppTypeContext = createContext([]);

const MyApp = ({ Component, pageProps }) => {
  const [appType, setAppType] = useState('track');
  const value = [appType, setAppType];

  const renderApp = () => (
    <AppTypeContext.Provider value={value}>
      <DynamicApp
        Component={Component}
        pageProps={{ ...pageProps, appType, setAppType }}
      />
    </AppTypeContext.Provider>
  );

  return renderApp();
};

export default MyApp;
