import { auth, firestore } from '../firebaseApp';
import type {
  ActivityData,
  CurrentActivity,
  CurrentCourse,
  CurrentModule,
  ModuleData,
  PreworkCourse,
} from '../types';
import { COLLECTIONS, SUBCOLLECTIONS } from '../constants';

/**
 * Looks up the activity details from a specific course
 *
 * @param courseId The ID of the requested course
 * @param moduleId The ID of the requested module
 * @param activityId The ID of the requested activity
 * @returns ActivityData object
 */
const getActivityDataFromCourseId = async (
  courseId: string,
  moduleId: string,
  activityId: string
): Promise<ActivityData> => {
  let activityData: ActivityData = {
    id: activityId,
    name: '',
    resources: [],
    group: false,
    submittable: false,
    type: '',
  };
  if (!courseId) return activityData;
  return firestore
    .collection(COLLECTIONS.COURSES)
    .doc(courseId)
    .collection(SUBCOLLECTIONS.MODULES)
    .doc(moduleId)
    .get()
    .then((snap) => {
      if (!snap.exists) return activityData;
      const { activities } = snap.data();
      const activity = activities.find(
        (act: ActivityData) => act.id === activityId
      );
      activityData.name = activity.name;
      activityData.resources = activity.resources;
      activityData.group = activity.group;
      activityData.submittable = activity.submittable;
      activityData.type = activity.type;
      return activityData;
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

const getAllCurrentData = (uid) => {
  let currentCourse: CurrentCourse = { id: '', name: '' };
  let currentModule: CurrentModule = { id: '', name: '', activities: [] };
  let currentActivity: CurrentActivity = { id: '', name: '', type: '' };
  let currentData = { currentCourse, currentModule, currentActivity };
  if (!uid) return currentData;
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .get()
    .then(async (snap) => {
      if (!snap.exists) return currentData;
      const { courseId, moduleId, activityId } = snap.data();
      const courseName = await getCourseNameFromId(courseId);
      currentCourse.id = courseId;
      currentCourse.name = courseName;
      currentModule = await getModuleDataFromId(courseId, moduleId);
      currentActivity = await getActivityDataFromCourseId(
        courseId,
        moduleId,
        activityId
      );
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

const getCompletionInfo = async (uid: string) => {
  if (!uid)
    return {
      activitiesCompleted: {},
      modulesCompleted: {},
      lessonsCompleted: {},
      tracksCompleted: {},
    };
  return firestore
    .collection('users')
    .doc(uid)
    .get()
    .then((snap) => {
      if (!snap.exists)
        return {
          activitiesCompleted: {},
          modulesCompleted: {},
          lessonsCompleted: {},
          tracksCompleted: {},
        };

      const data = snap.data();
      const {
        activitiesCompleted,
        modulesCompleted,
        lessonsCompleted,
        tracksCompleted,
      } = data;
      return {
        activitiesCompleted,
        modulesCompleted,
        lessonsCompleted,
        tracksCompleted,
      };
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

const getCourseNameFromId = async (courseId: string): Promise<string> => {
  if (!courseId) return '';
  return firestore
    .collection('courses')
    .doc(courseId)
    .get()
    .then((snap) => {
      if (!snap.exists) return '';
      return snap.data().name;
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

// queries firestore lessonsCompleted object to get
// the current track/lesson for the passed in course
// talks to FIRESTORE
const getCourseStatusForModules = (courseId) => {
  let currentActivity: CurrentActivity = { id: '', name: '', type: '' };
  let currentModule: CurrentModule = { id: '', name: '', activities: [] };
  if (!courseId) return { currentActivity, currentModule };

  const uid = auth.currentUser.uid;
  return firestore
    .collection('users')
    .doc(uid)
    .collection('courseStatus')
    .doc(courseId)
    .get()
    .then(async (snap) => {
      if (!snap.exists) return { currentActivity, currentModule };
      currentActivity = await getCurrentActivity(uid);
      currentModule = await getCurrentModule(uid);
      return { currentActivity, currentModule };
    });
};

const getCurrentActivity = async (uid: string): Promise<CurrentActivity> => {
  const currentActivity: CurrentActivity = { id: '', name: '', type: '' };
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .get()
    .then(async (snap) => {
      if (!snap.exists) return currentActivity;
      const { courseId, moduleId, activityId } = snap.data();
      const activityData: ActivityData = await getActivityDataFromCourseId(
        courseId,
        moduleId,
        activityId
      );
      currentActivity.id = activityData.id;
      currentActivity.name = activityData.name;
      currentActivity.type = activityData.type;
      return currentActivity;
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

const getCurrentModule = async (uid: string): Promise<ModuleData> => {
  let moduleData: ModuleData = { id: '', name: '', activities: [] };
  if (!uid) return moduleData;
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .get()
    .then(async (snap) => {
      if (!snap.exists) return moduleData;
      const { courseId, moduleId } = snap.data();
      moduleData = await getModuleDataFromId(courseId, moduleId);
      return moduleData;
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

/**
 * gets the modules subcollection on a course document with courseId
 * @param courseId Id of the course to get the module and activity lists from
 * @returns Module and Activity Lists specifc to the course
 */
const getModuleAndActivityLists = async (
  courseId: string
): Promise<{ moduleList: ModuleData[]; activityList: ActivityData[] }> => {
  const moduleList: ModuleData[] = [];
  const activityList: ActivityData[] = [];
  if (!courseId) return { moduleList, activityList };

  return firestore
    .collection(COLLECTIONS.COURSES)
    .doc(courseId)
    .collection(SUBCOLLECTIONS.MODULES)
    .get()
    .then((querySnap) => {
      if (!querySnap.docs.length) return { moduleList, activityList };

      querySnap.docs.forEach((doc) => {
        moduleList.push({
          activities: doc.data().activities,
          id: doc.id,
          name: doc.data().name,
          pos: doc.data().pos,
        });
      });

      // sort modules in order
      moduleList.sort((module1, module2) => {
        return module1.pos - module2.pos;
      });

      moduleList.forEach((module) => {
        activityList.push(...module.activities);
      });

      return { moduleList, activityList };
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

/**
 *
 * @param courseId The id of the requested course
 * @param moduleId The id of the requested module
 * @returns A promise which resolves to a ModuleData object
 */
const getModuleDataFromId = async (
  courseId: string,
  moduleId: string
): Promise<ModuleData> => {
  let moduleData = { id: moduleId, name: '', activities: [] };
  if (!courseId) return moduleData;
  return firestore
    .collection(COLLECTIONS.COURSES)
    .doc(courseId)
    .collection(SUBCOLLECTIONS.MODULES)
    .doc(moduleId)
    .get()
    .then((snap) => {
      if (!snap.exists) return moduleData;
      const { name, activities } = snap.data();
      moduleData.name = name;
      moduleData.activities = activities;
      return moduleData;
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

const getPreworkCourses = (): Promise<PreworkCourse[]> => {
  const courses = [];
  return firestore
    .collection(COLLECTIONS.COURSES)
    .where('type', '==', 'prework')
    .get()
    .then((querySnap) => {
      if (!querySnap.docs.length) return courses;
      return querySnap.docs.map((doc) => {
        return {
          id: doc.id,
          name: doc.data().name,
          type: doc.data().type,
        };
      });
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

const getPurchasedStatus = async (
  courseId: string,
  uid: string
): Promise<boolean> => {
  if (!uid || !courseId) return false;
  const { exists: purchased } = await firestore
    .collection('courses')
    .doc(courseId)
    .collection('purchased')
    .doc(uid)
    .get();

  return !!purchased;
};

const getUserCourses = async (uid: string): Promise<CurrentCourse[]> => {
  return firestore
    .collection(COLLECTIONS.USERS)
    .doc(uid)
    .get()
    .then(async (snap) => {
      if (!snap.exists) return [];
      const { courseIds } = snap.data();
      const namePromises = courseIds.map((id) => {
        return getCourseNameFromId(id);
      });
      const resolvedNamePromises = await Promise.all([...namePromises]);
      const courseObjects = courseIds.map((id, i) => {
        return {
          id,
          name: resolvedNamePromises[i],
        };
      });
      return courseObjects;
    })
    .catch((error) => {
      throw new error();
    });
};

export {
  getActivityDataFromCourseId,
  getAllCurrentData,
  getCourseStatusForModules,
  getCurrentActivity,
  getCurrentModule,
  getCompletionInfo,
  getModuleAndActivityLists,
  getModuleDataFromId,
  getPreworkCourses,
  getPurchasedStatus,
  getUserCourses,
};
