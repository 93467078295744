import { createContext } from 'react';

import type { CourseContextType } from './types';

const courseContext: CourseContextType = {
  currentCourse: { name: '', id: '' },
  currentTrack: { name: '', id: '' },
  currentLesson: { name: '', id: '', type: '', trackId: '' },
  activityList: [],
  currentModule: { name: '', id: '', activities: [] },
  currentActivity: {
    name: '',
    id: '',
    type: '',
    group: false,
    resources: [],
    submittable: false,
  },
  trackList: [],
  lessonList: [],
  moduleList: [],
};

const CourseContext = createContext(courseContext);

CourseContext.displayName = 'CourseContext';

export default CourseContext;
