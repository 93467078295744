import firebase from 'firebase/app';

import { firestore, firebaseApp } from './firebaseApp';
import { getCourseDoc } from './firestore/get/document';

// Get names from ids for courses, tracks, and lessons
const getCourseNameFromId = async (courseId) => {
  if (!courseId) return '';
  return firestore
    .collection('courses')
    .doc(courseId)
    .get()
    .then((snap) => {
      if (snap.data()) {
        return snap.data().name;
      }
      return '';
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

const getTrackNameFromId = (trackId) => {
  if (!trackId) return '';
  return firestore
    .collection('tracks')
    .doc(trackId)
    .get()
    .then((snap) => {
      if (snap.data()) {
        return snap.data().name;
      }
      return '';
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

const getLessonNameFromId = (lessonId) => {
  if (!lessonId) return '';
  return firestore
    .collection('lessons')
    .doc(lessonId)
    .get()
    .then((snap) => {
      if (snap.data()) {
        return snap.data().name;
      }
      return '';
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

const getLessonDataFromId = (lessonId) => {
  if (!lessonId) {
    return {
      name: '',
      type: '',
    };
  }
  return firestore
    .collection('lessons')
    .doc(lessonId)
    .get()
    .then((snap) => {
      if (snap.data()) {
        return {
          name: snap.data().name,
          type: snap.data().type,
        };
      }
      return {
        name: '',
        type: '',
      };
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

const getLessonDoc = async (lessonId) => {
  if (!lessonId) {
    return {
      contentType: '',
      createdAt: '',
      groupProject: '',
      lessonUrl: '',
      name: '',
      submittable: false,
      type: '',
      googleSlidesId: '',
    };
  }
  return await firestore
    .collection('lessons')
    .doc(lessonId)
    .get()
    .then((snap) => {
      if (snap.data()) {
        return {
          ...snap.data(),
          id: snap.id,
        };
      } else {
        return {
          contentType: '',
          createdAt: '',
          groupProject: '',
          lessonUrl: '',
          name: '',
          submittable: false,
          type: '',
          googleSlidesId: '',
        };
      }
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

// gets the trackList for the passed in courseId
// n + 1 requests
// returns a track with a name and an id
const getTracksInCourseId = async (courseId) => {
  if (!courseId) return [];
  return firestore
    .collection('courses')
    .doc(courseId)
    .get()
    .then(async (snap) => {
      const trackObjects = [];
      if (snap.data()) {
        const trackIds = snap.data().trackIds;
        for await (let id of trackIds) {
          const name = await getTrackNameFromId(id);
          let trackObject = {
            id,
            name,
          };
          trackObjects.push(trackObject);
        }
        return trackObjects;
      } else {
        return [];
      }
    });
};

// retrieves tracks from the subcollection on a course document
const getTracksFromSubcollection = async (courseId) => {
  const tracks = await firestore
    .collection('courses')
    .doc(courseId)
    .collection('tracks')
    .get()
    .then((query) => {
      const trackArr = [];
      query.docs.forEach((doc) => {
        trackArr.push({
          ...doc.data(),
        });
      });
      return trackArr;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
  return tracks;
};

// takes a course id and returns the track list and lesson list
const getLessonAndTrackLists = async (courseId) => {
  const trackList = [];
  const lessonList = [];
  if (!courseId) return { trackList, lessonList };

  return firestore
    .collection('courses')
    .doc(courseId)
    .collection('tracks')
    .get()
    .then((snap) => {
      // if we find tracks docs in the subcollection
      if (snap.docs.length) {
        // for each track doc, push some data to the trackList
        snap.docs.forEach((doc) => {
          trackList.push({
            name: doc.data().name,
            id: doc.id,
            lessons: doc.data().lessons,
            pos: doc.data().pos,
          });
        });
      }

      // sort the tracks in order of position property,
      // lessons will already be in order
      trackList.sort((track1, track2) => {
        return track1.pos - track2.pos;
      });

      trackList.forEach((track) => {
        lessonList.push(...track.lessons);
      });

      return { trackList, lessonList };
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

// gets the lessonList for the passed in trackId
// from firestore and returns the lessonList
const getLessonsInTrackId = async (trackId) => {
  if (!trackId) return [];
  return firestore
    .collection('tracks')
    .doc(trackId)
    .get()
    .then(async (snap) => {
      const lessonObjects = [];
      if (snap.data()) {
        const lessonIds = snap.data().lessonIds;
        for await (let id of lessonIds) {
          const lessonData = await getLessonDataFromId(id);
          const lessonObject = {
            id,
            trackId,
            name: lessonData.name,
            type: lessonData.type,
          };
          lessonObjects.push(lessonObject);
        }
        return lessonObjects;
      } else {
        return [];
      }
    });
};

const getLessonsInCourse = async (courseId) => {
  if (!courseId) return;
  return firestore
    .collection('courses')
    .doc(courseId)
    .get()
    .then(async (snap) => {
      if (snap.data()) {
        const trackIds = snap.data().trackIds;
        const lessonList = [];
        for await (let id of trackIds) {
          const lessons = await getLessonsInTrackId(id);
          lessonList.push(...lessons);
        }
        return lessonList;
      } else {
        return [];
      }
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

const getCurrentCourse = async (uid) => {
  return firestore
    .collection('users')
    .doc(uid)
    .get()
    .then(async (snap) => {
      if (snap.data() && snap.data().courseId !== '') {
        const id = snap.data().courseId;
        const name = await getCourseNameFromId(id);
        return {
          id,
          name,
        };
      } else {
        return {
          id: '',
          name: '',
        };
      }
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

const getCurrentTrack = async (uid) => {
  return firestore
    .collection('users')
    .doc(uid)
    .get()
    .then(async (snap) => {
      if (snap.data() && snap.data().trackId !== '') {
        const id = snap.data().trackId;
        const name = await getTrackNameFromId(id);
        return {
          id,
          name,
        };
      } else {
        return {
          id: '',
          name: '',
        };
      }
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

const getCurrentLesson = async (uid) => {
  return firestore
    .collection('users')
    .doc(uid)
    .get()
    .then(async (snap) => {
      if (snap.data() && snap.data().lessonId !== '') {
        const id = snap.data().lessonId;
        const lessonData = await getLessonDataFromId(id);
        return {
          id,
          name: lessonData.name,
          type: lessonData.type,
        };
      } else {
        return {
          id: '',
          name: '',
          type: '',
        };
      }
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

// gets an array of courseIds that the current logged in
// student is enrolled in
const getUserCourses = async (uid) => {
  const courseObjects = [];
  return firestore
    .collection('users')
    .doc(uid)
    .get()
    .then(async (snap) => {
      if (snap.data()) {
        const courseIds = snap.data().courseIds;
        for await (let id of courseIds) {
          const name = await getCourseNameFromId(id);
          const courseObject = {
            id,
            name,
          };
          courseObjects.push(courseObject);
        }
        return courseObjects;
      } else {
        return [];
      }
    });
};

const getUsersInCourse = (courseId) => {
  if (!courseId) return [];
  return firestore
    .collection('courses')
    .doc(courseId)
    .get()
    .then((snap) => {
      if (snap.data()) {
        return snap.data().students;
      }
      return [];
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

/**
 * Queries firestore for a course document to retrieve the studentsArr field
 * @param {string} courseId The id of the requested course
 * @returns studentsArr field on the course document
 */
const getStudentsInCourse = (courseId) => {
  if (!courseId) return [];
  return firestore
    .collection('courses')
    .doc(courseId)
    .get()
    .then((snap) => {
      if (snap.data()) {
        return snap.data().studentsArr;
      }
      return [];
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

// TODO: switch this to use a query which searches for courses that
// a given instructor is a part of
const getInstructorCourses = async (uid) => {
  const courseObjects = [];
  if (!uid) return courseObjects;
  return firestore
    .collection('users')
    .doc(uid)
    .get()
    .then(async (snap) => {
      if (snap.data()) {
        const courseIds = snap.data().courseIds;
        for await (let id of courseIds) {
          const course = await getCourseDoc(id);
          courseObjects.push(course);
        }
      }
      return courseObjects;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

const getCourseMetaData = async (courseId) => {
  if (!courseId)
    return {
      id: '',
      name: '',
      type: '',
      startDate: '',
      endDate: '',
      calendarUrl: '',
    };
  return firestore
    .collection('courses')
    .doc(courseId)
    .get()
    .then((snap) => {
      const metaData = {
        id: courseId || '',
        name: snap.data().name || '',
        type: snap.data().type || '',
        startDate: snap.data().startDate || '',
        endDate: snap.data().endDate || '',
        calendarUrl: snap.data().calendarUrl || '',
      };
      return { metaData };
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

// queries firestore lessonsCompleted object to get
// the current track/lesson for the passed in course
// talks to FIRESTORE
const getCourseStatusForTracks = (courseObj) => {
  let currentLesson = { id: '', name: '', type: '' };
  let currentTrack = { id: '', name: '' };
  if (!courseObj.id) return { currentLesson, currentTrack };

  const uid = firebaseApp.auth().currentUser.uid;
  return firestore
    .collection('users')
    .doc(uid)
    .collection('courseStatus')
    .doc(courseObj.id)
    .get()
    .then(async (snap) => {
      let currentLessonId, currentTrackId;
      let currentTrackName;
      let currentLessonData;
      if (snap.data()) {
        currentLessonId = snap.data().lessonId;
        currentTrackId = snap.data().trackId;
        currentLessonData = await getLessonDataFromId(currentLessonId);
        currentTrackName = await getTrackNameFromId(currentTrackId);
        currentLesson = {
          id: currentLessonId,
          name: currentLessonData.name,
          type: currentLessonData.type,
        };
        currentTrack = {
          id: currentTrackId,
          name: currentTrackName,
        };
      } else {
        console.log('course status not available');
      }
      return { currentLesson, currentTrack };
    });
};

// sets the status of the courseStatus sub collection under a user
// this is used to retrieve the current lesson and track as
// well as the next lesson for a specific course
const setCourseStatusForTracks = (courseObj, trackObj, lessonObj) => {
  const uid = firebaseApp.auth().currentUser.uid;
  return firestore
    .collection('users')
    .doc(uid)
    .collection('courseStatus')
    .doc(courseObj.id)
    .set(
      {
        trackId: trackObj.id,
        lessonId: lessonObj.id,
      },
      { merge: true }
    )
    .catch((err) => {
      throw new Error(err.message);
    });
};

const setCourseProgressForTracks = async (
  courseId,
  numLessonsCompleted,
  percentCompleted
) => {
  const uid = firebaseApp.auth().currentUser.uid;
  return await firestore
    .collection('users')
    .doc(uid)
    .collection('courseStatus')
    .doc(courseId)
    .set(
      {
        numLessonsCompleted,
        percentCompleted,
      },
      { merge: true }
    )
    .catch((err) => {
      throw new Error(err.message);
    });
};

const getCourseProgress = async (uid, courseId) => {
  if (!uid || !courseId) {
    return {
      numLessonsCompleted: 0,
      percentCompleted: 0,
    };
  }
  return await firestore
    .collection('users')
    .doc(uid)
    .collection('courseStatus')
    .doc(courseId)
    .get()
    .then((snap) => {
      if (snap.data()) {
        return {
          numLessonsCompleted: snap.data().numLessonsCompleted,
          percentCompleted: snap.data().percentCompleted,
        };
      } else {
        return { numLessonsCompleted: 0, percentCompleted: 0 };
      }
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

// gets the users enrolled courses, current lesson, track, and course,
// and the completed object from firestore and sets state
// talks to FIRESTORE
const getCompletionInfo = async (uid) => {
  if (!uid) return { lessonsCompleted: {}, tracksCompleted: {} };
  return firestore
    .collection('users')
    .doc(uid)
    .get()
    .then((snap) => {
      if (snap.data()) {
        const data = snap.data();
        const lessonsCompleted = data.lessonsCompleted;
        const tracksCompleted = data.tracksCompleted;
        return {
          lessonsCompleted,
          tracksCompleted,
        };
      } else {
        return {
          lessonsCompleted: {},
          tracksCompleted: {},
        };
      }
    });
};

const getAllUserData = async (uid) => {
  const results = await Promise.all([
    getUserCourses(uid),
    getCurrentCourse(uid),
    getCurrentTrack(uid),
    getCurrentLesson(uid),
    getCompletionInfo(uid),
  ]);
  const user = await firestore
    .collection('users')
    .doc(uid)
    .get()
    .then((snapshot) => {
      const user = {
        uid: snapshot.id,
        name: snapshot.data().name,
        email: snapshot.data().email,
      };
      return user;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  const [courses, currentCourse, currentTrack, currentLesson, completionInfo] =
    results;
  return {
    ...user,
    courses,
    currentCourse,
    currentTrack,
    currentLesson,
    completionInfo,
  };
};

const getLessonData = async (lessonName, type) => {
  return firestore
    .collection('lessons')
    .where('name', '==', lessonName)
    .where('type', '==', type)
    .get()
    .then((snapshot) => {
      if (snapshot.docs[0]) {
        return {
          message: 'Successfully retrieved lesson data',
          lessonUrl: snapshot.docs[0].data().lessonUrl,
          lessonId: snapshot.docs[0].id,
          lessonName,
          submittable: snapshot.docs[0].data().submittable,
          groupProject: snapshot.docs[0].data().groupProject,
          createdAt: snapshot.docs[0].data().createdAt,
        };
      } else {
        return {
          message: `No lesson with type and name: ${type} - ${lessonName}`,
          lessonUrl: '',
          lessonId: '',
          lessonName: '',
          submittable: false,
          groupProject: false,
          createdAt: null,
        };
      }
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

const getLessonDataFromTrack = async (courseId, trackId, lessonId) => {
  if (!courseId || !trackId || !lessonId) return {};
  return firestore
    .collection('courses')
    .doc(courseId)
    .collection('tracks')
    .doc(trackId)
    .get()
    .then((snap) => {
      if (snap.data()) {
        const { lessons } = snap.data();
        const lesson = lessons.find((lesson) => lesson.id === lessonId);
        return lesson;
      }
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};
// sets/updates the info about the current course, track, lesson
// and next lesson in firestore, as well as setting the completion info
const setCurrentLessonData = (
  uid,
  {
    currentCourse,
    currentTrack,
    currentLesson,
    lessonsCompleted,
    tracksCompleted,
  }
) => {
  const courseId = currentCourse.id;
  const trackId = currentTrack.id;
  const lessonId = currentLesson.id;
  return firestore.collection('users').doc(uid).set(
    {
      courseId,
      trackId,
      lessonId,
      lessonsCompleted: lessonsCompleted,
      tracksCompleted: tracksCompleted,
    },
    { merge: true }
  );
};

const getSlideDeckData = async (slideDeckName) => {
  return firestore
    .collection('lessons')
    .where('name', '==', slideDeckName)
    .where('type', '==', 'slides')
    .get()
    .then((snap) => {
      if (snap.docs[0]) {
        const data = snap.docs[0].data();
        return {
          ...data,
          lessonId: snap.docs[0].id,
        };
      } else {
        throw new Error('Slide Deck Lesson DNE');
      }
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

const getLessonMarkdownFromUrl = async (lessonUrl) => {
  if (!lessonUrl) return '';
  const response = await fetch(lessonUrl);
  const text = await response.text();
  return text;
};

const getLessonMarkdownFromId = (lessonId) => {
  if (!lessonId) return '';
  return firestore
    .collection('lessons')
    .doc(lessonId)
    .get()
    .then((snap) => {
      if (snap.data()) {
        return snap.data().lessonUrl;
      } else {
        return '';
      }
    })
    .then(async (url) => {
      const response = await fetch(url);
      const text = await response.text();
      return text;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

const getOnboardingStatus = (uid) => {
  let onboardingComplete = false;
  return firestore
    .collection('users')
    .doc(uid)
    .get()
    .then((snap) => {
      if (snap.data() && snap.data().onboardingComplete) {
        onboardingComplete = snap.data().onboardingComplete;
      }
      return onboardingComplete;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

const completeOnboardingFS = (uid) => {
  if (!uid) {
    return;
  }
  return firestore
    .collection('users')
    .doc(uid)
    .update({
      onboardingComplete: true,
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

const getLastCompleted = async (lessonsCompleted, courseId) => {
  const tracks = lessonsCompleted[courseId];

  const sortableList = [];
  for (let track in tracks) {
    for (let lesson in lessonsCompleted[courseId][track]) {
      sortableList.push({
        trackId: track,
        id: lesson,
        time: lessonsCompleted[courseId][track][lesson].seconds,
      });
    }
  }
  if (!sortableList.length) {
    const lastCompleted = {
      lesson: { id: '', name: '', type: '' },
      track: { id: '', name: '' },
    };
    return lastCompleted;
  }
  const sortedList = sortableList.sort((a, b) => {
    return a.time - b.time;
  });

  const lessonId = sortedList[sortedList.length - 1].id;
  const lessonData = await getLessonDataFromId(lessonId);
  const trackId = sortedList[sortedList.length - 1].trackId;
  const trackName = await getTrackNameFromId(trackId);
  const lastCompleted = {
    lesson: { id: lessonId, name: lessonData.name, type: lessonData.type },
    track: { id: trackId, name: trackName },
  };
  return lastCompleted;
};

const getNumberOfStudentsInCourse = (courseId) => {
  return firestore
    .collection('courses')
    .doc(courseId)
    .get()
    .then((snap) => {
      return snap.data().students.length;
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

// adds the courseId to the courseIds array on a user document in firestore
// courseId is required and either email or uid reqired
const addCourseToStudentDoc = async (email, courseId, uid) => {
  if (!courseId || (!email && !uid)) return;
  const lessonsCompletedPath = `lessonsCompleted.${courseId}`;
  const tracksCompletedPath = `tracksCompleted.${courseId}`;

  try {
    // if a uid is provided, use that
    // otherwise find the id using the email
    // using IFFE to make a firestore call in expression
    const userId =
      uid ??
      (await (async () =>
        firestore
          .collection('users')
          .where('email', '==', email)
          .get()
          .then((snap) => snap.docs[0].id))());

    return firestore
      .collection('users')
      .doc(userId)
      .update({
        courseIds: firebase.firestore.FieldValue.arrayUnion(courseId),
        [lessonsCompletedPath]: {},
        [tracksCompletedPath]: {},
      });
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};

const removeCourseFromStudentDoc = async (email, courseId) => {
  return firestore
    .collection('users')
    .where('email', '==', email)
    .get()
    .then((snap) => {
      const userId = snap.docs[0].id;
      const lessonsCompletedPath = `lessonsCompleted.${courseId}`;
      const tracksCompletedPath = `tracksCompleted.${courseId}`;
      firestore
        .collection('users')
        .doc(userId)
        .update({
          courseId: '',
          lessonId: '',
          trackId: '',
          courseIds: firebase.firestore.FieldValue.arrayRemove(courseId),
          [lessonsCompletedPath]: firebase.firestore.FieldValue.delete(),
          [tracksCompletedPath]: firebase.firestore.FieldValue.delete(),
        });
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};

const addUserToCourse = async (studentObject, courseId) => {
  return firestore
    .collection('courses')
    .doc(courseId)
    .update({
      students: firebase.firestore.FieldValue.arrayUnion(studentObject.email),
      studentsArr: firebase.firestore.FieldValue.arrayUnion(studentObject),
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

const submitDataToFirestore = (data, collectionName, docId) => {
  if (!data || !collectionName || !docId) return;
  return firestore
    .collection(collectionName)
    .doc(docId)
    .set(
      {
        ...data,
      },
      { merge: true }
    )
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

const setDueDateForAssignment = async (courseId, assignmentId, dueDate) => {
  return firestore
    .collection('courses')
    .doc(courseId)
    .collection('assignments')
    .doc(assignmentId)
    .set(
      {
        dueDate,
      },
      { merge: true }
    )
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

const getAssignmentInfo = async (courseId, assignmentId) => {
  return firestore
    .collection('courses')
    .doc(courseId)
    .collection('assignments')
    .doc(assignmentId)
    .get()
    .then(async (snap) => {
      if (!snap.exists) return {};
      return snap.data();
    })
    .catch((error) => {
      console.error(error.message);
      throw error;
    });
};

export {
  getCourseNameFromId,
  getLessonNameFromId,
  getLessonDataFromId,
  getLessonDoc,
  getTrackNameFromId,
  getTracksInCourseId,
  getLessonAndTrackLists,
  getTracksFromSubcollection,
  getLessonsInTrackId,
  getLessonsInCourse,
  getCurrentCourse,
  getCurrentTrack,
  getCurrentLesson,
  getInstructorCourses,
  getUserCourses,
  getUsersInCourse,
  getStudentsInCourse,
  getCourseStatusForTracks,
  setCourseStatusForTracks,
  setCourseProgressForTracks,
  getCourseProgress,
  getCompletionInfo,
  getAllUserData,
  getLessonData,
  getLessonDataFromTrack,
  setCurrentLessonData,
  getSlideDeckData,
  getLessonMarkdownFromUrl,
  getLessonMarkdownFromId,
  getOnboardingStatus,
  completeOnboardingFS,
  getNumberOfStudentsInCourse,
  addCourseToStudentDoc,
  removeCourseFromStudentDoc,
  addUserToCourse,
  getCourseMetaData,
  submitDataToFirestore,
  setDueDateForAssignment,
  getAssignmentInfo,
};
