const trackState = {
  numLessons: 0,
  lessonsCompleted: {},
  currentTrack: { id: '', name: '' },
  currentLesson: { id: '', name: '', type: '', trackId: '' },
  lessonList: [],
  trackList: [],
  tracksCompleted: {},
};

export { trackState };
