const moduleState = {
  numActivities: 0,
  currentModule: { id: '', name: '', activities: [] },
  currentActivity: {
    id: '',
    name: '',
    type: '',
    group: false,
    resources: [],
    submittable: false,
  },
  moduleList: [],
  activityList: [],
  modulesCompleted: {},
  activitiesCompleted: {},
  setCourse: async (course) => {},
};

export { moduleState };
